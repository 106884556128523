<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">question List</h4>
          <div class="d-flex justify-content-start">
            <router-link v-if="$store.getters['auth/checkAccess']('questions/create')"
                         :to="'/questions/create'"
                         class="btn btn-info btn-wd" wide>
              Add New
              <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
            </router-link>

          </div>


        </div>

        <div>
          <general-data-table
            ref="table"
            :advanceSearchFilter="advanceSearchFilter"
            :method="'POST'"
            :row-key="'id'"
            @reorder="reorder($event)"
            :api-url="'questions/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">

            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/checkAccess']('questions/change-active-status')"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link
                    class="btn-warning btn-simple btn-link"
                    :to="'/questions/edit/'+scope.row.id">
                    <i class="fa fa-edit"></i>
                  </router-link>
                </template>


                <a v-if="$store.getters['auth/checkAccess']('questions/delete')"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)">
                  <i class="fa fa-times"></i></a>
              </template>

            </el-table-column>
          </general-data-table>
        </div>

      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this question?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import {Switch as LSwitch} from '@/components'



export default {
  components: {
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    FgSelect,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Question', value: 'question', minWidth: '200', align: 'center'},
        {label: 'Question Section', value: 'question_section', minWidth: '200', align: 'center'},
        {label: 'Question Type', value: 'question_type', minWidth: '200', align: 'center'},
      ],
      url : process.env.VUE_APP_SERVER_IMAGE_URL,

      advanceSearchFilter: [
        {
          key: 'questions.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'questions.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
        {
          key: 'questions.question',
          type: 'text',
          frontType:'simple',
          label: 'Question',
          class: 'col-6',
          value: null,
        },
      ],

      filterBuilders: {
        questions: []
      },

      filters: {
        member_id: null
      },

      deleteModalVisibility: false,
      toDeleteId: undefined
    }
  },

  async mounted() {
    this.$refs.table.createDragAndDropSorting();
    let response = await this.axios.get("questions/builder");
    this.filterBuilders.questions = response.data.pageList;
  },

  methods: {

    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active
      }
      this.axios.post("questions/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "question updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },


    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        await this.axios.delete("questions/delete/" + this.toDeleteId);
        this.$refs.table.getData("updateData");
        this.$notify({
          message: "Question deleted successfully",
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },

    async reorder(data) {
      try {
        let body = [];
        data.forEach((row, i) => {
          body.push({"id": row.id, "sort_order": i + 1})
        });

        await this.axios.post("questions/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },

    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.member_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
  },
}
</script>

